 //文件流下载
 function DownFiles(blob, filename) {
     var fileName = decodeURI(filename, "utf-8");
     let type = fileName.slice(fileName.indexOf("."));
     // 如果后端用encodeURI对文件名进行了编码，前端需用decodeURI进行解码（主要为处理特殊字符）
     // 由于ie不支持download属性，故需要做兼容判断
     if (navigator.appVersion.toString().indexOf(".NET") > 0) {
         // ie独有的msSaveBlob属性，data.data为Blob文件流
         window.navigator.msSaveBlob(blob, fileName);
     } else {
         // 以下流程即为文章开始的下载流程
         var binaryData = [];
         binaryData.push(blob);
         let url = window.URL.createObjectURL(
             new Blob(binaryData, {
                 type: `application/${type}`,
             })
         );
         let link = document.createElement("a");
         link.style.display = "none";
         link.href = url;
         link.download = fileName;
         document.body.appendChild(link);
         link.click();
         window.URL.revokeObjectURL(link.href);
     }
 }

 export default DownFiles;