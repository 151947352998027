<template>
  <Modal
    v-model="modal"
    sticky
    scrollable
    :mask-closable="true"
    width="60%"
    class="custom-modal"
    @on-cancel="handleCancel"
  >
    <template #header>
      <p class="title">批量添加</p>
      <div class="modal-close" @click="handleCancel">
        关闭
        <span>ESC</span>
      </div>
    </template>
    <div class="file-box">
      <div class="file-box-header">
        <div class="logo">
          <img src="../../../assets/img/file.png" alt />
        </div>
        <div class="file-info">
          <p class="fileName" v-if="file.name">{{ file.name }}</p>
          <p class="dataSize" v-if="file.size">{{ file.size }}</p>
        </div>
      </div>
      <div
        class="uoload-status"
        v-if="
          !(
            result.process == 'success' &&
            result.errorData.current == result.errorData.count
          )
        "
      >
        <div
          class="verify"
          v-if="
            result.process == 'success' &&
            result.errorData &&
            result.errorData.current != result.errorData.count
          "
        >
          <h3>正在校验数据目录</h3>
          <Progress :percent="result.percent" stroke-color="#18b714" />
        </div>
        <div class="verify" v-if="result.process == 'fail'">
          <h3>校验失败</h3>
          <Progress :percent="result.percent" stroke-color="#f30707">
            <span class="ivu-progress-text-inner">{{ result.percent }}%</span>
          </Progress>
        </div>
      </div>
      <div class="process-info" v-if="failArr.length > 0 || errorInfo != ''">
        <div v-if="failArr.length > 0 || errorInfo != ''">
          <Icon type="ios-checkmark-circle" />
          <span>已上传文件</span>
        </div>

        <template v-if="failArr.length > 0">
          <div v-for="(item, i) in failArr" :key="i">
            <p>
              <Icon type="ios-close-circle" />
              <span>第{{ item.current }}条数据目录校验失败:</span>
            </p>
            <div class="specific">
              <p>详细原因：{{ item.reason }}</p>
            </div>
          </div>
        </template>
        <div v-if="failArr.length == 0">
          <p>
            <Icon type="ios-close-circle" />
            <span>数据目录校验失败: {{ errorInfo }}</span>
          </p>
        </div>
      </div>
      <div
        class="success-info"
        v-if="
          result.process == 'success' &&
          result.errorData.current == result.errorData.count
        "
      >
        <div class="logo">
          <Icon type="ios-checkmark-circle" />
        </div>
        <h2>添加成功</h2>
        <p>数据目录已保存成草稿，返回列表页面可查看并提交复核</p>

        <div class="btn btn-default " @click="returnList">返回列表</div>
      </div>
    </div>

    <template #footer></template>
  </Modal>
</template>
  
  <script setup>
import { Modal, Message } from "view-ui-plus";
import {
  reactive,
  ref,
  onMounted,
  inject,
  onBeforeUnmount,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";
import bus from "@/utils/bus";
let modal = ref(false);

let result = ref({
  process: "fail",
  percent: 0,
});

let failArr = ref([]);

let file = ref({});

const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
defineExpose({ modify });

let router = useRouter();
let account = ref("");
//获取用户信息
const getUserInfo = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    if (user != null && user != undefined) {
      // headers.value.Authorization = "Bearer " + user.auth_token;
      account.value = user.userInfo.cstnetId;
    }
  }
};

//字节单位转换
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 B"; //Bytes
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

let websocket = ref("");

// const connectWebsocket = () => {
//   if (typeof WebSocket === "undefined") {
//     return Modal.error({
//       title: "提醒",
//       content: "您的浏览器不支持WebSocket",
//     });
//   } else {
//     let protocol = "ws";
//     let url = "";
//     if (window.location != undefined && window.location.protocol == "https:") {
//       protocol = "wss";
//     }
//     url = `${protocol}://10.0.85.159:88/api/socket/${account.value}`; //测试环境scoket地址
//     // url = `${protocol}://dc.casdc.cn/api/socket/${account.value}`; //生产环境scoket地址
//     // 打开一个websocket
//     websocket.value = new WebSocket(url);
//     // 建立连接
//     websocket.value.onopen = open;
//     // 客户端接收服务端返回的数据
//     websocket.value.onmessage = getMessage;
//     // 发生错误时
//     websocket.value.onerror = error;
//     // 关闭连接
//     websocket.value.onclose = close;
//   }
// };

// 返回列表
const returnList = () => {
  router.push({ name: "FillingList" });
};

const open = () => {
  // console.log("socket连接成功");
};
const error = () => {
  console.log("连接错误");
};

let errorInfo = ref("");
const getMessage = (msg) => {
  // console.log(msg);
  // failArr.value = [];
  errorInfo.value = "";
  result.value = {
    process: "fail",
    percent: 0,
  };
  if (isJSON(msg.data)) {
    let data = JSON.parse(msg.data);
    if (data && data.errorData && data.errorData.status == "fail") {
      failArr.value.push(data.errorData);
    }

    result.value = {
      ...data,
      process: data.process,
      percent: data.percent,
    };
  } else {
    errorInfo.value = msg.data;
  }
};
const send = (params) => {
  console.log("发送数据");
  // websocket.value.send(params);
};
const close = () => {
  console.log("socket已经关闭");
};
onMounted(() => {
  //接收通知打开弹框
  bus.on("openValidate", () => {
    modify();
  });
  //接收上传成功的文件信息
  bus.on("fileInfo", (data) => {
    file.value = {
      ...data,
      size: formatBytes(data.size),
      process: data.response.code == 200 ? "success" : "fail",
    };
  });
  //接收上传失败的文件信息
  bus.on("errorInfo", (data) => {
    file.value = {
      ...data,
      size: formatBytes(data.size),
    };
  });
});
//判断是否是json字符串
const isJSON = (str) => {
  if (typeof str == "string") {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
};
//关闭弹框
const handleCancel = () => {
  modal.value = false;
  failArr.value = [];
  errorInfo.value = "";
};
onBeforeUnmount(() => {
  // if (websocket.value != "") {
  //   websocket.value.onclose = close;
  // }
  failArr.value = [];
  errorInfo.value = "";
  bus.all.delete("fileInfo");
  bus.all.delete("openValidate");
  bus.all.delete("errorInfo");
});

getUserInfo();
// connectWebsocket();
</script>
  
  <style lang="scss" scoped>
.file-box {
  border-radius: 4px 4px 0px 0px;
  border: solid 1px #dddddd;
  .file-box-header {
    padding: 15px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(#f6f6f6, #f6f6f6),
      linear-gradient(#2c399d, #2c399d);
    background-blend-mode: normal, normal;
    border-bottom: solid 1px #dddddd;
    .logo {
      width: 50px;
      height: 50px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#2c399d, #2c399d);
      background-blend-mode: normal, normal;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img {
        display: block;
        width: 24px;
        height: 30px;
      }
    }
    .file-info {
      .fileName {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 33px;
        color: #111111;
      }
      .dataSize {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 33px;
        color: #555555;
      }
    }
  }
  .uoload-status {
    padding: 30px;
    max-width: 100%;
    overflow: hidden;
    border-bottom: solid 1px #dddddd;
    > div {
      h3 {
        font-family: PingFangSC-Regular;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #111111;
        text-align: center;
        margin-bottom: 30px;
      }
      :deep(.ivu-progress) {
        .ivu-progress-text {
          float: left;
          padding-right: 10px;
          font-family: PingFangSC-Medium;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #888888;
          margin-left: 0;
        }
        .ivu-progress-outer {
          padding-left: 90px;
          margin-left: -90px;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }
  .process-info {
    padding: 30px;
    min-height: 260px;
    max-height: 40vh;
    overflow: hidden;
    overflow-y: auto;
    > div {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 34px;
      letter-spacing: 0px;
      color: #555555;
      .ivu-icon {
        font-size: 18px;
        margin-right: 10px;
      }
      .ivu-icon-ios-checkmark-circle {
        color: #18b714;
      }
      .ivu-icon-ios-close-circle {
        color: #f30707;
      }
      .specific {
        margin-left: 28px;
      }
    }
  }
}
.success-info {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    i {
      font-size: 34px;
      color: #18b714;
    }
  }
  h2 {
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    color: #18b714;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  > p {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    color: #555555;
  }
  .btn {
    margin-top: 50px;
  }
}
</style>